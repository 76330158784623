#mainContainer {
    position: relative;
    width: 100%;
    /* change this width to adjust ads container size with respect to player */
    height: auto;
}

#content {
    width: 100% !important;
    height: auto !important;
}

#adContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

#adContainer div:first-child,
#adContainer iframe {
    width: 100% !important;
    height: 100% !important;
    top: 0px !important;
    left: 0px !important;
    position: absolute !important;
}

#contentElement {
    width: 640px;
    height: 360px;
    overflow: hidden;
}

#playButton {
    margin-top: 10px;
    vertical-align: top;
    width: 350px;
    height: 60px;
    padding: 0;
    font-size: 22px;
    color: white;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    background: #2c3e50;
    border: 0;
    border-bottom: 2px solid #22303f;
    cursor: pointer;
    -webkit-box-shadow: inset 0 -2px #22303f;
    box-shadow: inset 0 -2px #22303f;
}

#adContainer {
    z-index: 100;
}

#adContainer.adshide {
    visibility: hidden;
    opacity: 0;
}

/*-----ads points css start here--*/
.adPointsWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}

.adPointsWrapper .cuePoint {
    position: absolute;
    width: 8px;
    height: 5px;
    background: #FFFF00;
    top: 13px;
    pointer-events: none;
}

.MuiSlider-thumb,
.MuiSlider-track {
    z-index: 2;
}

/*-----ads points css ends here--*/